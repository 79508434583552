import * as React from "react";

import DefaultLayout from "../layouts/default";

import { MenuProvider } from '../hooks/Menu/Provider';

const NotFoundPage: React.FC = () => {
  return (
    <MenuProvider>
      <DefaultLayout 
        title={"Not found"} 
        supLine={"Lost in space?"} 
        subtitle={["Oh nein, diese Seite existiert nicht."]}
        showLogo={true}
        moodNegative={true}>
      </DefaultLayout>
    </MenuProvider>
  )
};

export default NotFoundPage;
